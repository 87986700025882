export const environment = {
    name: 'dev',
    production: true,
    API_BASE_PATH: 'https://api.dev.cathago.de/api',
    APP_BASE_URL: 'https://app.dev.cathago.de',
    WHATS_NEW_URL: 'https://doc.clickup.com/3380780/p/h/375hc-14321/fd51b560e32af3a',
    HOTJAR_ID: 'DUMMY',
    GOOGLE_ANALYTICS_MEASUREMENT_ID: 'OFF',
    FIREBASE_CONFIG: {
        apiKey: 'AIzaSyCly0HfhcGnrO4Mon8CElWQRPG-WFVyPvc',
        authDomain: 'webapp-procurement-cathago.firebaseapp.com',
        databaseURL: 'https://webapp-procurement-cathago-default-rtdb.europe-west1.firebasedatabase.app',
        accountPreferencesDatabaseUrl: 'https://dev-and-staging-account-preferences.europe-west1.firebasedatabase.app',
        projectId: 'webapp-procurement-cathago',
        storageBucket: 'webapp-procurement-cathago.appspot.com',
        messagingSenderId: '905174883878',
        appId: '1:905174883878:web:4a4d5b3ba7f9183de6d0e7',
        measurementId: 'G-BH9LVJL10Q',
        vapidKey: 'BJqKnXuhb_cjGUwylN52YS95pIK57uDEx3RYxpuoTucvN2sodF8PzGTc88DPrE4_t8dWFDjWsOJvsG8ZNs31rWo'
    },
    TYPE_SENSE_CONFIG: {
        apiKey: 'zRQ6STuHWZ2Bu3R9fDq8Zaop54TiBZdA',
        host: 'km5ydgazbslqrfwep-1.a1.typesense.net',
        port: 443,
        protocol: 'https',
    },
    LoggerSettings: {
        sendToServer: false,
        logzioUrl: 'https://listener.logz.io:8071',
        logzioToken: 'ONeQAdURwjULoygWQWTmetXUaDVCBUiK',
        logType: 'dev-jupiter-log'
    },
    GOOGLE_MAPS_CONFIG: {
        apiKey: 'AIzaSyCly0HfhcGnrO4Mon8CElWQRPG-WFVyPvc',
        mapsUrl: `https://maps.googleapis.com/maps/api/js?libraries=places&loading=async&key=`
    }
};
