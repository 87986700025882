import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class LocaleService {

    private localeId = 'de';

    constructor(private translateService: TranslateService,
                @Inject(DOCUMENT) private document: Document) {
        this.subscribeToLanguageChangeEvent();
    }

    private subscribeToLanguageChangeEvent() {
        this.translateService.onLangChange.subscribe(langChangeEvent => {
            this.setLocale(langChangeEvent?.lang);
        })
    }

    getLocale(): string {
        return this.localeId;
    }

    private setLocale(locale: string): void {
        this.localeId = locale;
        this.document.documentElement.lang = locale;
    }
}
