import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {FormatterUtil} from '../util/formatter.util';

@Injectable({
    providedIn: 'root'
})
export class DateTimeFormatStore {

    private _dateFormat = new BehaviorSubject<string>(FormatterUtil.getDateFormat());
    public dateFormat: Observable<string>;
    private _timeFormat = new BehaviorSubject<string>(FormatterUtil.getTimeFormat());
    public timeFormat: Observable<string>;

    constructor() {
        this.dateFormat = this._dateFormat.asObservable();
        this.timeFormat = this._timeFormat.asObservable();
    }

    public publishDateFormat(dateFormat: string) {
        this._dateFormat.next(dateFormat);
    }

    public publishTimeFormat(timeFormat: string) {
        this._timeFormat.next(timeFormat);
    }
}
