import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CompanyAccountStore {

    private _companyAccountId = new BehaviorSubject<string>('');
    public companyAccountId: Observable<string>;

    constructor() {
        this.companyAccountId = this._companyAccountId.asObservable();
    }

    public publish(companyAccountId: string) {
        this._companyAccountId.next(companyAccountId);
    }
}
