import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClientWrapperService} from '../../../home-layout/common/services/http-wrapper.service';
import {ROUTING} from '../../../home-layout/common/constant/routing.constant';
import {JwtTokenDto, UserOutDto} from "../../../auth-layout/common/model/user.model";

@Injectable({
    providedIn: 'root'
})
export class SupportUserService {
    private gatewayProcurementUrl = '/gateways/procurement';
    private supportUserUrl = `/supportUsers`;
    private loginUrl = `/login`;

    constructor(private readonly http: HttpClientWrapperService) {
    }

    login(supportLoginInDto: SupportLoginInDto): Observable<SupportLoginOutDto> {
        return this.http.post(`${this.gatewayProcurementUrl}${this.supportUserUrl}${this.loginUrl}`, supportLoginInDto);
    }

    isSupportUserLoggedIn() {
        return localStorage.getItem(ROUTING.SUPPORT) !== null;
    }

    saveSupportLoginFlag() {
        localStorage.setItem(ROUTING.SUPPORT, 'true');
    }

    clearSupportLoginFlag() {
        localStorage.removeItem(ROUTING.SUPPORT);
    }
}

export class SupportLoginInDto {
    username: string;
    password: string;
    endUsername: string;
    gateway: string;
}

export class SupportLoginOutDto {
    public token?: JwtTokenDto;
    public user?: UserOutDto;
}
