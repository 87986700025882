import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarRef} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {ComponentType} from '@angular/cdk/portal';

@Injectable({
    providedIn: 'root'
})
export class LibSnackbarService {

    constructor(private snackBar: MatSnackBar,
                private translateService: TranslateService) {
    }

    public open(defaultMessage = this.translateService.instant('Excited! It\'s coming soon!')) {
        this.snackBar.open(defaultMessage, 'CLOSE', {
            duration: 4000,
            horizontalPosition: 'right'
        });
    }

    public openFromComponent<T>(component: ComponentType<T>, config?: MatSnackBarConfig): MatSnackBarRef<T> {
        config = this.getDefaultConfig(config);
        return this.snackBar.openFromComponent(component, config);
    }

    private getDefaultConfig(config: MatSnackBarConfig<any>) {
        return config ? this.updateConfig(config) : {
            panelClass: 'custom-snackbar'
        };
    }

    private updateConfig(config: MatSnackBarConfig<any>) {
        config.panelClass = 'custom-snackbar' + (config?.panelClass ? ' ' + config?.panelClass : '');
        config.horizontalPosition = config?.horizontalPosition || 'right';
        config.verticalPosition = config?.verticalPosition || 'bottom';
        config.duration = config?.duration || 5000;
        return config;
    }
}
