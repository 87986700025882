import {Routes} from '@angular/router';
import {ROUTING} from "./cathago/home-layout/common/constant/routing.constant";
import {AuthGuard} from "./cathago/home-layout/common/guard/auth.gaurd";

export const APP_ROUTES: Routes = [
    {
        path: '',
        data: {
            name: ROUTING.HOME
        },
        canActivate: [AuthGuard],
        loadChildren: () => import('./cathago/home-layout/home-layout.route')
            .then(route => route.HOME_LAYOUT_ROUTES)
    },
    {
        path: ROUTING.AUTH,
        data: {
            name: ROUTING.AUTH
        },
        canActivate: [AuthGuard],
        loadChildren: () => import('./cathago/auth-layout/auth-layout.route')
            .then(route => route.AUTH_LAYOUT_ROUTES)
    },
    // TODO: bring SupportLayout
    // {
    //     path: ROUTING.SUPPORT,
    //     data: {
    //         name: ROUTING.AUTH
    //     },
    //     canActivate: [AuthGuard],
    //     loadChildren: () => import('./cathago/support-layout/support-layout.module')
    //         .then(m => m.SupportLayoutModule)
    // },
    // TODO: bring LibPageNotFoundComponent
    // {
    //     path: '**',
    //     component: LibPageNotFoundComponent
    // }
];
