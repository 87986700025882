import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {CompanyAccountStore} from './company-account-store.service';
import {LoginOutDto} from '../../../auth-layout/common/model/user.model';

// service
import {UserMeOutDto, UserMeService} from '../services/user.me.service';
import {AnalyticsService} from '../services/analytics.service';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ROUTING} from '../constant/routing.constant';
import {CAT_CONSTANTS} from '../util/constants.util';
import {MemberOutDto} from "../../../top-app-bar/company-account-setting/common/model/ca.member.model";
import {CurrencyStore} from './currency-store.service';
import {DateTimeFormatStore} from "./date-format-store.service";
import {SupportUserService} from "../../../support-layout/common/service/support-user.service";
import {UserTokenFirebaseService} from "../firebase/user-token-firebase.service";
import {LibCurrencyUtil} from "../../../../library/input/common/util/lib-currency.util";
import {FormatterUtil} from "../util/formatter.util";

@Injectable({
    providedIn: 'root'
})
export class LoggedInUserStore {
    public activeUser: Observable<UserMeOutDto>;
    private _activeUser = new BehaviorSubject<UserMeOutDto>(null);
    private userMeOutDto: UserMeOutDto;

    constructor(private router: Router,
                private companyAccountStore: CompanyAccountStore,
                private currencyStore: CurrencyStore,
                private dateFormatStore: DateTimeFormatStore,
                private supportUserService: SupportUserService,
                private userMeService: UserMeService,
                private analyticsService: AnalyticsService,
                private userTokenFirebaseService: UserTokenFirebaseService) {
        this.activeUser = this._activeUser.asObservable();
    }

    getLoggedInUser(): Observable<UserMeOutDto> {
        return this.userMeOutDto
            ? of(this.userMeOutDto)
            : this.getUserDetailsFromServer();
    }

    processAccessToken(loginOutDto: LoginOutDto) {
        const accessToken = loginOutDto?.token?.accessToken;
        this.saveAuthToken(accessToken);
    }

    processRefreshToken(loginOutDto: LoginOutDto) {
        const refreshToken = loginOutDto?.token?.refreshToken;
        this.saveRefreshAuthToken(refreshToken);
    }

    publishMember(memberOutDto: MemberOutDto) {
        this.companyAccountStore.publish(memberOutDto?.companyAccountOutDto?.id);
    }

    refresh(callback?) {
        this.loadData((userMeOutDto) => {
            if (callback) {
                callback(userMeOutDto);
            }
        });
    }

    isLoggedIn(): boolean {
        return this.getTokenFromLocalStorage() !== null;
    }

    logout() {
        this.userTokenFirebaseService.delete()?.then(() => {
            this.clearUserResources();
        });
    }

    clearAuthToken() {
        localStorage.removeItem(CAT_CONSTANTS.AUTH_TOKEN);
    }

    clearRefreshToken() {
        localStorage.removeItem(CAT_CONSTANTS.REFRESH_AUTH_TOKEN);
    }

    processUserMeData(userMeOutDto: UserMeOutDto) {
        this.userTokenFirebaseService.create(userMeOutDto?.userOutDto?.userId);
        this.userMeOutDto = userMeOutDto;
        this._activeUser.next(userMeOutDto);
        this.analyticsService.registerUserForAnalytics(userMeOutDto);
        this.publishMember(userMeOutDto?.companyAccountUserOutDto?.member);
        this.storeCurrency(userMeOutDto);
        this.storeDateTime(userMeOutDto);
    }

    clearProjectId() {
        localStorage.removeItem('projectId');
    }

    getLoggedInUsername(): string {
        return this.userMeOutDto.userOutDto.username;
    }

    getLoggedInUserRoleName(): string {
        return this.userMeOutDto.userOutDto?.roleOutDto?.roleName;
    }

    getLoggedInUserRoleType(): string {
        return this.userMeOutDto.userOutDto?.roleOutDto?.roleType;
    }

    getLoggedInUserId(): string {
        return this._activeUser?.value?.userOutDto?.userId;
    }

    private storeCurrency(userMeOutDto: UserMeOutDto): void {
        const currencyModel = this.getCompanyCurrencyModel(userMeOutDto);
        LibCurrencyUtil.getInstance().setCurrencyModel(currencyModel);
        this.currencyStore.publish(currencyModel);
    }

    private storeDateTime(userMeOutDto: UserMeOutDto): void {
        const dateTimeFormat = this.getCompanyDateFormat(userMeOutDto);
        const firstSpaceIndex = dateTimeFormat.indexOf(' ');
        if (firstSpaceIndex !== -1) {
            const dateFormat = dateTimeFormat.substring(0, firstSpaceIndex);
            const timeFormat = dateTimeFormat.substring(firstSpaceIndex + 1);

            FormatterUtil.setDateFormat(dateFormat);
            this.dateFormatStore.publishDateFormat(dateFormat);
            FormatterUtil.setTimeFormat(timeFormat);
            this.dateFormatStore.publishTimeFormat(timeFormat);
        }
    }

    private getCompanyCurrencyModel(userMeOutDto: UserMeOutDto) {
        const currencyCode = userMeOutDto.companyAccountUserOutDto?.member?.companyAccountOutDto?.companyOutDto?.currency;
        if (currencyCode != null) {
            return LibCurrencyUtil.getInstance().getCurrencyModel(currencyCode);
        }
        return LibCurrencyUtil.getInstance().defaultCurrencyModel();
    }

    private getCompanyDateFormat(userMeOutDto: UserMeOutDto) {
        const dateTimeFormat = userMeOutDto.companyAccountUserOutDto?.member?.companyAccountOutDto?.companyOutDto?.dateFormat;
        if (dateTimeFormat != null) {
            return dateTimeFormat;
        }
        return FormatterUtil.getDateFormat();
    }

    private getUserDetailsFromServer() {
        return this.userMeService.find().pipe(map(userMeOutDto => {
            this.processUserMeData(userMeOutDto);
            return userMeOutDto;
        }));
    }

    private saveAuthToken(token: any) {
        localStorage.setItem(CAT_CONSTANTS.AUTH_TOKEN, token);
    }

    private saveRefreshAuthToken(refreshToken: any) {
        localStorage.setItem(CAT_CONSTANTS.REFRESH_AUTH_TOKEN, refreshToken);
    }

    private getTokenFromLocalStorage() {
        return localStorage.getItem(CAT_CONSTANTS.AUTH_TOKEN);
    }

    private loadData(callback) {
        this.userMeService.find().subscribe(userMeOutDto => {
            this.processUserMeData(userMeOutDto);
            callback(userMeOutDto);
        });
    }

    private clearSupportUserFlag() {
        if (this.supportUserService.isSupportUserLoggedIn()) {
            this.supportUserService.clearSupportLoginFlag();
        }
    }

    private navigateToLogin() {
        this.router.navigate([ROUTING.SLASH + ROUTING.AUTH + ROUTING.SLASH + ROUTING.LOGIN])
            .then(() => {
                window.location.reload();
            });
    }

    private clearUserResources() {
        this.clearAuthToken();
        this.clearRefreshToken();
        this.clearSupportUserFlag();
        this.clearProjectId();
        this.navigateToLogin();
    }
}
