import {Injectable} from '@angular/core';
import {LibSnackbarService} from "../../../../../library/snackbar/service/lib-snackbar.service";
import {NotificationOutDto} from "./notification.service";

@Injectable({
    providedIn: 'root'
})
export class PushNotificationService {

    isNotificationVisible = false;
    notificationsQueue = [];
    private NOTIFICATION_TIMEOUT = 5000;


    constructor(private libSnackbarService: LibSnackbarService) {
    }

    addToQueue(notificationOutDto: NotificationOutDto) {
        this.notificationsQueue.push(notificationOutDto);
        this.show();
    }

    show() {
        if (!this.isNotificationVisible && this.notificationsQueue?.length > 0) {
            this.isNotificationVisible = true;
            // TODO: add NotificationSnackbarComponent in part 2
            // this.libSnackbarService.openFromComponent(NotificationSnackbarComponent,
            //     this.getConfig(this.notificationsQueue.shift()))
            //     .afterDismissed().subscribe(this.handleCloseResult());
        }
    }

    private getConfig(notificationOutDto: NotificationOutDto): any {
        return {
            data: this.getData(notificationOutDto),
            duration: this.NOTIFICATION_TIMEOUT
        };
    }

    private getData(notificationOutDto: NotificationOutDto): any {
        return {
            data: notificationOutDto,
            timeout: this.NOTIFICATION_TIMEOUT
        };
    }

    private handleCloseResult(): () => void {
        return () => {
            this.isNotificationVisible = false;
            this.show();
        };
    }

}
