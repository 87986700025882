import {Injectable} from '@angular/core';
import {HttpClientWrapperService} from './http-wrapper.service';
import {Observable} from 'rxjs';
import {UserOutDto} from "../../../auth-layout/common/model/user.model";
import {
    CompanyAccountUserOutDto
} from "../../../top-app-bar/company-account-setting/common/model/ca.company-account-user.model";
import {ProjectMinOutDto} from "../../setup/project/common/model/project.model";

@Injectable({
    providedIn: 'root'
})
export class UserMeService {
    private gatewayProcurementUrl = '/v2/gateways/procurement';
    private userMeUrl = '/users/me';

    constructor(private readonly http: HttpClientWrapperService) {
    }

    find(): Observable<UserMeOutDto> {
        return this.http.get(`${this.getUrl()}`);
    }

    private getUrl() {
        return this.gatewayProcurementUrl + this.userMeUrl;
    }
}

export class UserMeOutDto {
    userOutDto: UserOutDto;
    companyAccountUserOutDto: CompanyAccountUserOutDto;
    projectMinOutDtos: ProjectMinOutDto[];
    selectedProjectMinOutDto?: ProjectMinOutDto;
}
