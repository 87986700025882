import {initializeApp, provideFirebaseApp} from "@angular/fire/app";
import {environment} from "../../../../../environments/environment";
import {getFirestore, provideFirestore} from "@angular/fire/firestore";
import {FIREBASE_OPTIONS} from "@angular/fire/compat";
import {getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService} from "@angular/fire/analytics";

const FIREBASE_PROVIDERS = [
    provideFirestore(() => getFirestore()),
    provideAnalytics(() => getAnalytics()),
    ScreenTrackingService,
    UserTrackingService,
    {provide: FIREBASE_OPTIONS, useValue: environment.FIREBASE_CONFIG},
    provideFirebaseApp(() => initializeApp(environment.FIREBASE_CONFIG))
]

export {FIREBASE_PROVIDERS};

