import {Component, Inject, LOCALE_ID, OnInit, Renderer2} from '@angular/core';
import {RouterModule, RouterOutlet} from '@angular/router';
import {DomSanitizer} from "@angular/platform-browser";
import {CommonModule, DOCUMENT} from "@angular/common";
import {MatIconRegistry} from "@angular/material/icon";
import {Platform} from "@angular/cdk/platform";
import {LibConfigService} from "./library/page-layout/common/service/lib-config.service";
import {LibStyleService, Style} from "./library/page-layout/common/service/lib-style.service";
import {LibLayoutService} from "./library/page-layout/common/service/lib-layout.service";
import {colorVariables} from "../@vex/config/color-variables";
import {GoogleMapLoaderService} from "./cathago/home-layout/common/services/google-map-loader.service";
import {SetupComponent} from "./cathago/home-layout/setup/setup.component";

import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import localePl from '@angular/common/locales/pl';
import localeJa from '@angular/common/locales/ja';
import {NgxParticlesModule} from "@tsparticles/angular";

registerLocaleData(localeDe);
registerLocaleData(localeFr);
registerLocaleData(localePl);
registerLocaleData(localeJa);

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        CommonModule,
        RouterOutlet,
        RouterModule,
        SetupComponent,
        NgxParticlesModule
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
    title = 'kepler10c';

    constructor(private matIconRegistry: MatIconRegistry,
                private domSanitizer: DomSanitizer,
                private configService: LibConfigService,
                private styleService: LibStyleService,
                private renderer: Renderer2,
                private platform: Platform,
                @Inject(DOCUMENT) private document: Document,
                @Inject(LOCALE_ID) private localeId: string,
                private layoutService: LibLayoutService,
                //private route: ActivatedRoute,
                //private router: Router,
                // private splashScreenService: LibSplashScreenService,
                //private snackbarService: SnackbarService,
                // private dialog: MatDialog,
                private googleMapLoaderService: GoogleMapLoaderService,
                // private notificationService: NotificationService,
                // private loggedInUserStore: LoggedInUserStore
    ) {
        if (this.platform.BLINK) {
            this.renderer.addClass(this.document.body, 'is-blink');
        }

        this.updateThemeConfig();
        this.styleService.setStyle(Style.light);
        this.changeColor();
        this.addCustomIconsToRegistry();
    }

    private updateThemeConfig() {
        this.configService.updateConfig({
            sidenav: {
                title: '',
                imageUrl: '../assets/img/cathago/logo/cathago-long-logo-bg-transparent.png'
            },
            toolbar: {
                fixed: false
            },
            footer: {
                visible: true,
                fixed: false
            }
        });
    }

    ngOnInit() {
        this.loadGoogleMapsScript();
        this.checkUpdateIsAvailable();
    }

    private changeColor() {
        const cathagoThemeColor = colorVariables['teal'];
        if (this.document) {
            this.document.documentElement.style.setProperty('--color-primary',
                cathagoThemeColor.default.replace('rgb(', '').replace(')', ''));
            this.document.documentElement.style.setProperty('--color-primary-contrast',
                cathagoThemeColor.contrast.replace('rgb(', '').replace(')', ''));
        }
    }

    private addCustomIconsToRegistry() {
        this.matIconRegistry.addSvgIcon(
            'language-de',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/cathago/icon/language-de.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'language-en',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/cathago/icon/language-en.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'language-fr',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/cathago/icon/language-fr.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'language-pl',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/cathago/icon/language-pl.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'language-ja',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/cathago/icon/language-ja.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'chevron-down',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/cathago/icon/chevron-down.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'chevron-down-gray',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/cathago/icon/chevron-down-gray.svg')
        );
    }

    private checkUpdateIsAvailable(): void {
        /*if (this.loggedInUserStore.isLoggedIn()) {
            this.notificationService.getWebappVersion().subscribe(latestWebappVersion => {
                const currentVersion = require('../../package.json').version;
                console.log('currentVersion:', currentVersion, ' latestWebappVersion:', latestWebappVersion);
                if (this.compareVersionStringsRightToLeft(latestWebappVersion, currentVersion)) {
                    this.openUpdateNowDialog(latestWebappVersion);
                }
            });
        }*/
    }

    private openUpdateNowDialog(latestWebappVersion: string) {
        /*this.dialog.open(UpdateAvailableComponent, {
                width: '50%',
                data: {latestWebappVersion}
            }
        ).afterClosed().subscribe(() => {
            document.location.reload(); // clear the service worker cache
            window.location.reload();
        });*/
    }

    private compareVersionStringsRightToLeft(latestWebappVersion: string, currentVersion: string): boolean {
        const latestVersions: number[] = latestWebappVersion.split('.').map(Number).reverse();
        const currentVersions: number[] = currentVersion.split('.').map(Number).reverse();

        if (latestVersions.length !== 3 || currentVersions.length !== 3) {
            return false;
        } else if (latestVersions[2] > currentVersions[2]) {
            return true;
        } else if (latestVersions[1] > currentVersions[1]) {
            return true;
        } else if (latestVersions[0] > currentVersions[0]) {
            return true;
        } else {
            return false;
        }
    }

    private loadGoogleMapsScript(): void {
        if (!document.querySelector('script[src^="https://maps.googleapis.com"]')) {
            let script = document.createElement('script');
            script = this.googleMapLoaderService.getScriptWithAttributes(script);
            this.googleMapLoaderService.handleOnload(script);
            this.googleMapLoaderService.handleOnError(script);
            document.head.appendChild(script);
        }
    }
}
