import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {DeepPartial} from '../../../common/interfaces/deep-partial.type';
import {mergeDeep} from '../../../common/utils/merge-deep';
import {LibLayoutService} from './lib-layout.service';
import {configs} from './configs';
import {ConfigName} from '../../../common/interfaces/config-name.model';
import {LibConfig} from '../../../common/interfaces/lib-config.model';

@Injectable({
    providedIn: 'root'
})
export class LibConfigService {

    defaultConfig = ConfigName.apollo;

    configs: LibConfig[] = configs;

    private _configSubject = new BehaviorSubject(this.configs.find(c => c.id === this.defaultConfig));
    config$ = this._configSubject.asObservable();

    constructor(@Inject(DOCUMENT) private document: Document,
                private layoutService: LibLayoutService) {
        this.config$.subscribe(config => this._updateConfig(config));
    }

    setConfig(config: ConfigName) {
        const settings = this.configs.find(c => c.id === config);

        if (settings) {
            this._configSubject.next(settings);
        }
    }

    updateConfig(config: DeepPartial<LibConfig>) {
        this._configSubject.next(mergeDeep({...this._configSubject.getValue()}, config));
    }

    updateSidenavState(state: 'expanded' | 'collapsed') {
        this.updateConfig({
            sidenav: {
                state
            }
        });
    }

    private _updateConfig(config: LibConfig) {
        const body = this.document.body;

        this.configs.forEach(c => {
            if (body.classList.contains(c.id)) {
                body.classList.remove(c.id);
            }
        });

        body.classList.add(config.id);

        config.sidenav.state === 'expanded' ? this.layoutService.expandSidenav() : this.layoutService.collapseSidenav();

        // Workaround so charts and other externals know they have to resize on Layout switch
        if (window) {
            window.dispatchEvent(new Event('resize'));

            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 200);
        }
    }
}
