import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {CAT_CONSTANTS} from '../util/constants.util';
import {CustomHttpUrlEncodingCodecUtil} from '../util/custom-http-url-encoding-codec.util';
import {ExceptionService} from './exception.service';
import {RequestParam} from '../model/request-param.model';

@Injectable({
    providedIn: 'root'
})
export class HttpClientWrapperService {
    private readonly baseUrl: string;

    private readonly requestOptions = {
        params: new HttpParams({encoder: new CustomHttpUrlEncodingCodecUtil()}),
        headers: new HttpHeaders()
            .append('Content-Type', 'application/json')
    };

    constructor(private readonly http: HttpClient,
                private exceptionService: ExceptionService) {
        this.baseUrl = environment.API_BASE_PATH;
    }

    get<T>(url: string, requestParams?: RequestParam[], errorCallback?: (error) => Observable<any>): Observable<T> {
        this.appendRequestParams(requestParams);
        return this.http.get<T>(this.baseUrl + url, this.requestOptions)
            .pipe(this.catchError(errorCallback));
    }

    post<T>(url: string, data?: any, requestParams?: RequestParam[], errorCallback?: (error) => Observable<any>): Observable<T> {
        this.appendRequestParams(requestParams);
        return this.http.post<T>(this.baseUrl + url, data, this.requestOptions)
            .pipe(this.catchError(errorCallback));
    }

    patch<T>(url: string, data?: any, requestParams?: RequestParam[], errorCallback?: (error) => Observable<any>): Observable<T> {
        this.appendRequestParams(requestParams);
        return this.http.patch<T>(this.baseUrl + url, data, this.requestOptions)
            .pipe(this.catchError(errorCallback));
    }

    put<T>(url: string, data: any, requestParams?: RequestParam[], errorCallback?: (error) => Observable<any>): Observable<T> {
        this.appendRequestParams(requestParams);
        return this.http.put<T>(this.baseUrl + url, data, this.requestOptions)
            .pipe(this.catchError(errorCallback));
    }

    delete(url: string, requestParams?: RequestParam[], errorCallback?: (error) => Observable<any>) {
        this.appendRequestParams(requestParams);
        return this.http.delete(this.baseUrl + url, this.requestOptions)
            .pipe(this.catchError(errorCallback));
    }

    deleteWithRequestBody(url: string, data?: any, requestParams?: RequestParam[], errorCallback?: (error) => Observable<any>) {
        this.appendRequestParams(requestParams);
        const requestOptions = {
            params: this.requestOptions.params,
            headers: this.requestOptions.headers,
            body: data
        };
        return this.http.delete(this.baseUrl + url, requestOptions)
            .pipe(this.catchError(errorCallback));
    }

    head(url: string, requestParams?: RequestParam[], errorCallback?: (error) => Observable<any>) {
        this.appendRequestParams(requestParams);
        return this.http.head(this.baseUrl + url, this.requestOptions)
            .pipe(this.catchError(errorCallback));
    }

    private appendRequestParams(requestParams: RequestParam[]) {
        this.requestOptions.params = new HttpParams({encoder: new CustomHttpUrlEncodingCodecUtil()});
        if (requestParams !== null && requestParams !== undefined) {
            requestParams.forEach(requestParam =>
                this.requestOptions.params = this.requestOptions.params.append(requestParam.key, requestParam.value));
        }
        this.addToken();
    }

    private addToken() {
        const accessToken = localStorage.getItem(CAT_CONSTANTS.AUTH_TOKEN);
        accessToken ? this.updateAccessToken(accessToken) : this.resetHeaders();
    }

    private resetHeaders() {
        this.requestOptions.headers = new HttpHeaders();
    }

    private catchError(errorCallback: (error) => Observable<any>) {
        return catchError(errorCallback ? errorCallback : this.exceptionService.catchError);
    }

    private updateAccessToken(accessToken: string) {
        if (this.requestOptions.headers.has('Authorization')) {
            this.requestOptions.headers = this.requestOptions.headers.set('Authorization', accessToken);
        } else {
            this.requestOptions.headers = this.requestOptions.headers.append('Authorization', accessToken);
        }
    }
}
