import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {CurrencyModel} from '../model/currency.model';
import {LibCurrencyUtil} from "../../../../library/input/common/util/lib-currency.util";

@Injectable({
    providedIn: 'root'
})
export class CurrencyStore {

    private _currencyModel = new BehaviorSubject<CurrencyModel>(LibCurrencyUtil.getInstance().defaultCurrencyModel());
    public currencyModel: Observable<CurrencyModel>;

    constructor() {
        this.currencyModel = this._currencyModel.asObservable();
    }

    public publish(currencyModel: CurrencyModel) {
        this._currencyModel.next(currencyModel);
    }
}
