import {Injectable} from '@angular/core';
// TODO : FIX IT LATER
// import {LANGUAGE} from '../../../auth-layout/common/model/user.model';
import {environment} from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GoogleMapLoaderService {

    private isApiLoaded = false;
    private isPlacesApiLoaded = false;
    private languageUrl = '&language=';

    constructor() {
    }

    public set setMapApiStatus(isApiLoaded: boolean) {
        this.isApiLoaded = isApiLoaded;
    }

    public get getMapApiStatus(): boolean {
        return this.isApiLoaded;
    }

    public set setPlacesApiStatus(isPlacesApiLoaded: boolean) {
        this.isPlacesApiLoaded = isPlacesApiLoaded;
    }

    public get getPlacesApiStatus(): boolean {
        return this.isPlacesApiLoaded;
    }

    public getScriptWithAttributes(script: HTMLScriptElement): HTMLScriptElement {
        script.src = `${environment.GOOGLE_MAPS_CONFIG.mapsUrl}${environment.GOOGLE_MAPS_CONFIG.apiKey}${this.getLanguageUrl()}`;
        script.async = true;
        script.defer = true;
        return script;
    }

    public handleOnload(script: HTMLScriptElement): void {
        script.onload = () => {
            this.setMapAndPlacesApiStatus(true, true);
        };
    }

    public handleOnError(script: HTMLScriptElement): void {
        script.onerror = (error) => {
            this.setMapAndPlacesApiStatus(false, false);
        };
    }

    public getLanguageUrl(preferredLanguage?: string): string {
        if (preferredLanguage) {
            return this.languageUrl + preferredLanguage;
        } else {
            return this.languageUrl + this.getSelectedLanguageFromLocalStorage();
        }
    }

    private getSelectedLanguageFromLocalStorage(): string {
        const selectedLanguage = localStorage.getItem('selectedLanguage');
        //  TODO : FIX IT LATER
        // return selectedLanguage === null ? LANGUAGE.DE : selectedLanguage?.toLowerCase();
        return selectedLanguage === null ? 'de' : selectedLanguage?.toLowerCase();
    }

    private setMapAndPlacesApiStatus(mapApiStatus: boolean, placesApiStatus: boolean): void {
        this.setMapApiStatus = mapApiStatus;
        this.setPlacesApiStatus = placesApiStatus;
    }
}
