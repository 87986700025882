import {CurrencyPipe} from '@angular/common';
import icEuroSymbol from '@iconify/icons-ic/twotone-euro-symbol';
import icYen from '@iconify/icons-ic/twotone-currency-yen';
import icSell from '@iconify/icons-ic/twotone-sell';

export class LibCurrencyUtil {
    currencyModel: CurrencyModel = this.defaultCurrencyModel();
    private static libCurrencyUtil: LibCurrencyUtil;

    // NOTE: Method to get the single instance of the class
    public static getInstance(): LibCurrencyUtil {
        if (!LibCurrencyUtil.libCurrencyUtil) {
            LibCurrencyUtil.libCurrencyUtil = new LibCurrencyUtil();
        }
        return LibCurrencyUtil.libCurrencyUtil;
    }

    setCurrencyModel(currencyModel: CurrencyModel): void {
        this.currencyModel = currencyModel;
    }

    getFormattedCurrency(value: string, currencyPipe: CurrencyPipe): string {
        try {
            if (value !== '' && value != null) {
                return currencyPipe.transform(value, this.currencyModel.code, 'symbol', '',
                    LOCALE_CODE[this.currencyModel.code]);
            } else {
                return '--';
            }
        } catch (exception) {
            return value;
        }
    }

    convertStringToNumber(value: string): number {
        if (!value) return NaN;
        const locale = LOCALE_CODE[this.currencyModel.code] || 'de-DE'; // Default to 'de-DE'
        const formatParts = new Intl.NumberFormat(locale).formatToParts(12345.67);
        const decimalSeparator = formatParts.find(part => part.type === 'decimal')?.value || '.';
        const groupSeparator = formatParts.find(part => part.type === 'group')?.value || ',';
        let normalizedValue = value.replace(new RegExp(`\\${groupSeparator}`, 'g'), '');
        normalizedValue = normalizedValue.replace(new RegExp(`\\${decimalSeparator}`, 'g'), '.');
        if (!/^-?\d+(\.\d+)?$/.test(normalizedValue)) {
            return NaN; // Return NaN for invalid input
        }
        return parseFloat(normalizedValue);
    }

    validateCurrency(value: string): boolean {
        if (!value) return false;

        const sanitizedValue = value.replace(/[^0-9,.\s-]/g, '');
        const locale = LOCALE_CODE[this.currencyModel.code] || 'de-DE'; // Default to 'de-DE'
        const currencyDecimals: Record<string, number> = {
            JPY: 0,  // No decimal places for Japanese Yen
            EUR: 2,  // Euros use two decimal places
            USD: 2,  // US Dollars
            PLN: 2,  // Polish Złoty
            INR: 2,  // Indian Rupees
            GBP: 2,  // British Pounds
        };
        const decimalPrecision = currencyDecimals[this.currencyModel.code] ?? 2;
        const formatParts = new Intl.NumberFormat(locale).formatToParts(1234.5);
        const decimalSeparator = formatParts.find(part => part.type === 'decimal')?.value || '.';
        const groupSeparator = formatParts.find(part => part.type === 'group')?.value || ','; // Thousands separator
        const decimalCount = (sanitizedValue.match(new RegExp(`\\${decimalSeparator}`, 'g')) || []).length;
        if (decimalCount > 1) return false; // Multiple decimal separators are invalid
        const regex = new RegExp(`^-?\\d{0,3}([\\s${groupSeparator}]?\\d{3})*(\\${decimalSeparator}\\d{1,${decimalPrecision}})?$`);
        return regex.test(sanitizedValue);
    }

    getCurrencyIcon(): any {
        switch (this.currencyModel?.code) {
            case CURRENCY_CODE.JPY: // .toString(): // TODO: check and remove toString
                return icYen;
            case CURRENCY_CODE.PLN: // .toString(): // TODO: check and remove toString
                return icSell;
            case CURRENCY_CODE.EUR: // .toString(): // TODO: check and remove toString
            default:
                return icEuroSymbol;
        }
    }

    getCurrencyModel(code: CURRENCY_CODE): CurrencyModel {
        switch (code) {
            case CURRENCY_CODE.JPY: // .toString(): // TODO: check and remove toString
                return this.createCurrencyModel(CURRENCY_DISPLAY_TEXT.JPY, CURRENCY_SYMBOL.JPY, CURRENCY_CODE.JPY);
            case CURRENCY_CODE.PLN: // .toString():
                return this.createCurrencyModel(CURRENCY_DISPLAY_TEXT.PLN, CURRENCY_SYMBOL.PLN, CURRENCY_CODE.PLN);
            case CURRENCY_CODE.EUR: // .toString():
            default:
                return this.createCurrencyModel(CURRENCY_DISPLAY_TEXT.EUR, CURRENCY_SYMBOL.EUR, CURRENCY_CODE.EUR);
        }
    }

    getCurrencyModels(): CurrencyModel[] {
        const currencyModels = [];
        currencyModels.push(this.createCurrencyModel(CURRENCY_DISPLAY_TEXT.JPY, CURRENCY_SYMBOL.JPY, CURRENCY_CODE.JPY));
        currencyModels.push(this.createCurrencyModel(CURRENCY_DISPLAY_TEXT.PLN, CURRENCY_SYMBOL.PLN, CURRENCY_CODE.PLN));
        currencyModels.push(this.createCurrencyModel(CURRENCY_DISPLAY_TEXT.EUR, CURRENCY_SYMBOL.EUR, CURRENCY_CODE.EUR));
        return currencyModels;
    }

    defaultCurrencyModel(): CurrencyModel {
        return this.createCurrencyModel(CURRENCY_DISPLAY_TEXT.EUR, CURRENCY_SYMBOL.EUR, CURRENCY_CODE.EUR);
    }

    createCurrencyModel(displayText: CURRENCY_DISPLAY_TEXT, symbol: CURRENCY_SYMBOL, code: CURRENCY_CODE): CurrencyModel {
        return {
            displayText,
            symbol,
            code
        } as CurrencyModel;
    }
}

export class CurrencyModel {
    displayText?: string;
    symbol?: CURRENCY_SYMBOL;
    code?: CURRENCY_CODE;
}

export enum CURRENCY_DISPLAY_TEXT {
    EUR = 'Euro',
    PLN = 'Zloty',
    JPY = 'Yen'
}

export enum CURRENCY_SYMBOL {
    EUR = '€',
    PLN = 'zł',
    JPY = '￥'
}

export enum CURRENCY_CODE {
    EUR = 'EUR',
    PLN = 'PLN',
    JPY = 'JPY'
}

export enum LOCALE_CODE {
    EUR = 'de-DE',
    PLN = 'pl-PL',
    JPY = 'ja-JP'
}
