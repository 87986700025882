import {ApplicationConfig, importProvidersFrom, LOCALE_ID} from '@angular/core';
import {provideRouter} from '@angular/router';

import {MatPaginatorIntl} from "@angular/material/paginator";
import {MatPaginatorTranslator} from "./cathago/i18n/mat-paginator-translator/mat-paginator-translator";
import {APP_ROUTES} from "./app.routes";
import {HttpClient, provideHttpClient} from "@angular/common/http";
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {environment} from "../environments/environment";
import {LIB_ENVIRONMENT} from "./library/environment/injection-token/lib-environment.injection.token";
import {FIREBASE_PROVIDERS} from "./cathago/home-layout/common/constant/firebase.providers";
import {provideVex} from "../@vex/vex.provider";
import {vexConfigs} from "../@vex/config/vex-configs";
import {LocaleService} from "./cathago/common/locale.service";

export const appConfig: ApplicationConfig = {
    providers: [
        provideHttpClient(),
        provideRouter(APP_ROUTES),
        provideAnimationsAsync(),
        {
            provide: LOCALE_ID,
            useFactory: (localeService: LocaleService) => localeService.getLocale(),
            deps: [LocaleService]
        },
        provideVex({
            /**
             * The config that will be used by default.
             * This can be changed at runtime via the config panel or using the VexConfigService.
             */
            config: vexConfigs.apollo,
            /**
             * Only themes that are available in the config in tailwind.config.ts should be listed here.
             * Any theme not listed here will not be available in the config panel.
             */
            availableThemes: [
                {
                    name: 'Default',
                    className: 'vex-theme-default'
                },
                {
                    name: 'Teal',
                    className: 'vex-theme-teal'
                },
                {
                    name: 'Green',
                    className: 'vex-theme-green'
                },
                {
                    name: 'Purple',
                    className: 'vex-theme-purple'
                },
                {
                    name: 'Red',
                    className: 'vex-theme-red'
                },
                {
                    name: 'Orange',
                    className: 'vex-theme-orange'
                }
            ]
        }),
        importProvidersFrom(
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient],
                },
            })
        ),
        {
            provide: MatPaginatorIntl,
            useClass: MatPaginatorTranslator
        },
        {
            provide: LIB_ENVIRONMENT,
            useValue: environment
        },
        FIREBASE_PROVIDERS
    ]
};

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
