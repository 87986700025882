import {ConfigName} from '../../../common/interfaces/config-name.model';
import {LibConfig} from '../../../common/interfaces/lib-config.model';
import {mergeDeep} from "../../../common/utils/merge-deep";
import {VexColorScheme, VexTheme} from "../../../../../@vex/config/vex-config.interface";

const defaultConfig: LibConfig = {
    id: ConfigName.apollo,
    name: 'Apollo',
    imgSrc: '//vex-landing.visurel.com/assets/img/layouts/apollo.png',
    layout: 'horizontal',
    boxed: false,
    style: {
        themeClassName: VexTheme.TEAL, // Added Teal Theme
        colorScheme: VexColorScheme.LIGHT,
        borderRadius: {
            value: 0.5,
            unit: 'rem'
        },
        button: {
            borderRadius: {
                value: 9999,
                unit: 'px'
            }
        }
    },
    sidenav: {
        title: 'VEX',
        imageUrl: 'assets/img/demo/logo.svg',
        showCollapsePin: true,
        state: 'expanded'
    },
    toolbar: {
        fixed: true
    },
    navbar: {
        position: 'below-toolbar'
    },
    footer: {
        visible: true,
        fixed: true
    },
    pageLayout: 'default'
};

export const configs: LibConfig[] = [
    defaultConfig,
    mergeDeep({...defaultConfig}, {
        id: ConfigName.hermes,
        name: 'Hermes',
        imgSrc: '//vex-landing.visurel.com/assets/img/layouts/hermes.png',
        layout: 'vertical',
        boxed: true,
        toolbar: {
            fixed: false
        },
        footer: {
            fixed: false
        }
    }),
    mergeDeep({...defaultConfig}, {
        id: ConfigName.ares,
        name: 'Ares',
        imgSrc: '//vex-landing.visurel.com/assets/img/layouts/ares.png',
        toolbar: {
            fixed: false
        },
        navbar: {
            position: 'in-toolbar'
        },
        footer: {
            fixed: false
        }
    }),
    mergeDeep({...defaultConfig}, {
        id: ConfigName.zeus,
        name: 'Zeus',
        imgSrc: '//vex-landing.visurel.com/assets/img/layouts/zeus.png',
        sidenav: {
            state: 'collapsed'
        },
    }),
    mergeDeep({...defaultConfig}, {
        id: ConfigName.ikaros,
        name: 'Ikaros',
        imgSrc: '//vex-landing.visurel.com/assets/img/layouts/ikaros.png',
        layout: 'vertical',
        boxed: true,
        toolbar: {
            fixed: false
        },
        navbar: {
            position: 'in-toolbar'
        },
        footer: {
            fixed: false
        }
    })
];
