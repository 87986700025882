import {CurrencyPipe} from '@angular/common';
import icEuroSymbol from '@iconify/icons-ic/twotone-euro-symbol';
import icYen from '@iconify/icons-ic/twotone-currency-yen';
import icSell from '@iconify/icons-ic/twotone-sell';

export class LibCurrencyUtil {
    currencyModel: CurrencyModel = this.defaultCurrencyModel();
    private static libCurrencyUtil: LibCurrencyUtil;

    // NOTE: Method to get the single instance of the class
    public static getInstance(): LibCurrencyUtil {
        if (!LibCurrencyUtil.libCurrencyUtil) {
            LibCurrencyUtil.libCurrencyUtil = new LibCurrencyUtil();
        }
        return LibCurrencyUtil.libCurrencyUtil;
    }

    setCurrencyModel(currencyModel: CurrencyModel): void {
        this.currencyModel = currencyModel;
    }

    getFormattedCurrency(value: string, currencyPipe: CurrencyPipe): string {
        try {
            if (value !== '' && value != null) {
                return currencyPipe.transform(value, this.currencyModel.code, 'symbol', '',
                    LOCALE_CODE[this.currencyModel.code]);
            } else {
                return '--';
            }
        } catch (exception) {
            return value;
        }
    }

    getCurrencyIcon(): any {
        switch (this.currencyModel?.code) {
            case CURRENCY_CODE.JPY: // .toString(): // TODO: check and remove toString
                return icYen;
            case CURRENCY_CODE.PLN: // .toString(): // TODO: check and remove toString
                return icSell;
            case CURRENCY_CODE.EUR: // .toString(): // TODO: check and remove toString
            default:
                return icEuroSymbol;
        }
    }

    getCurrencyModel(code: CURRENCY_CODE): CurrencyModel {
        switch (code) {
            case CURRENCY_CODE.JPY: // .toString(): // TODO: check and remove toString
                return this.createCurrencyModel(CURRENCY_DISPLAY_TEXT.JPY, CURRENCY_SYMBOL.JPY, CURRENCY_CODE.JPY);
            case CURRENCY_CODE.PLN: // .toString():
                return this.createCurrencyModel(CURRENCY_DISPLAY_TEXT.PLN, CURRENCY_SYMBOL.PLN, CURRENCY_CODE.PLN);
            case CURRENCY_CODE.EUR: // .toString():
            default:
                return this.createCurrencyModel(CURRENCY_DISPLAY_TEXT.EUR, CURRENCY_SYMBOL.EUR, CURRENCY_CODE.EUR);
        }
    }

    defaultCurrencyModel(): CurrencyModel {
        return this.createCurrencyModel(CURRENCY_DISPLAY_TEXT.EUR, CURRENCY_SYMBOL.EUR, CURRENCY_CODE.EUR);
    }

    createCurrencyModel(displayText: CURRENCY_DISPLAY_TEXT, symbol: CURRENCY_SYMBOL, code: CURRENCY_CODE): CurrencyModel {
        return {
            displayText,
            symbol,
            code
        } as CurrencyModel;
    }
}

export class CurrencyModel {
    displayText?: string;
    symbol?: CURRENCY_SYMBOL;
    code?: CURRENCY_CODE;
}

export enum CURRENCY_DISPLAY_TEXT {
    EUR = 'Euro',
    PLN = 'Zloty',
    JPY = 'Yen'
}

export enum CURRENCY_SYMBOL {
    EUR = '€',
    PLN = 'zł',
    JPY = '￥'
}

export enum CURRENCY_CODE {
    EUR = 'EUR',
    PLN = 'PLN',
    JPY = 'JPY'
}

export enum LOCALE_CODE {
    EUR = 'de-DE',
    PLN = 'pl-PL',
    JPY = 'ja-JP'
}
