import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LoggerService {

    private loggerSettings = environment.LoggerSettings;

    constructor(private http: HttpClient) {
    }

    logError(error: Error) {
        if (this.loggerSettings.sendToServer) {
            this.http.post(this.loggerSettings.logzioUrl, {
                message: JSON.stringify(error, null, '\t')
            }, {
                headers: new HttpHeaders()
                    .append('Content-Type', 'text/plain')
                    .append('Accept', '*/*'),
                params: {
                    token: this.loggerSettings.logzioToken,
                    type: this.loggerSettings.logType
                }
            }).subscribe(() => {
            });
        }
    }
}
