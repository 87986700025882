import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {LoggedInUserStore} from "../store/logged-in-user-store.service";
import {ROUTING} from "../constant/routing.constant";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard {

    constructor(private loggedInUserStore: LoggedInUserStore,
                private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean | UrlTree {
        if (this.loggedInUserStore.isLoggedIn()) {
            if (route.data['name'] && route.data['name'] === 'auth') {
                return this.router.createUrlTree([ROUTING.SLASH + ROUTING.HOME]);
            }
            return true;
        } else if (route.data['name'] && route.data['name'] === 'auth') {
            return true;
        } else {
            return this.gotoLoginPage(state.url);
        }
    }

    private gotoLoginPage(redirectUrl: string): UrlTree {
        return this.router.createUrlTree(
            [ROUTING.SLASH + ROUTING.AUTH + ROUTING.SLASH + ROUTING.LOGIN],
            {queryParams: {redirectUrl}}
        );
    }
}
