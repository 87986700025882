import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ROUTING} from '../../../home-layout/common/constant/routing.constant';
import {CAT_CONSTANTS} from '../../../home-layout/common/util/constants.util';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {CustomHttpUrlEncodingCodecUtil} from '../../../home-layout/common/util/custom-http-url-encoding-codec.util';
import {environment} from '../../../../../environments/environment';
import {LoginOutDto} from '../model/user.model';
import {Observable} from 'rxjs';
import {LibSnackbarService} from "../../../../library/snackbar/service/lib-snackbar.service";
import {TokenUtil} from "../util/token.util";
import {RequestParam} from "../../../home-layout/common/model/request-param.model";

@Injectable({
    providedIn: 'root'
})
export class TokenService {

    private baseUrl: string;
    private userUrl = `/users`;
    private refreshTokenUrl = `/refreshToken`;
    private gatewayUrl = '/gateways/procurement';
    private redirectUrl: string;

    private readonly requestOptions = {
        params: new HttpParams({encoder: new CustomHttpUrlEncodingCodecUtil()}),
        headers: new HttpHeaders()
            .append('Content-Type', 'application/json')
    };

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private readonly http: HttpClient,
                private libSnackbarService: LibSnackbarService,
                private translateService: TranslateService) {
        this.setBaseUrl();
    }

    handle403(): void {
        const token = localStorage.getItem(CAT_CONSTANTS.AUTH_TOKEN);
        if (TokenUtil.isTokenExpired(token)) {
            this.checkForRedirectUrl();
            this.appendRequestParamsForRefreshToken([]);
            this.refreshToken();
        } else {
            this.refreshTokenExpired();
        }
    }

    private refreshToken(): void {
        this.fetchRefreshToken().subscribe(loginOutDto => {
            this.handleSuccess(loginOutDto);
        }, error => {
            this.refreshTokenExpired();
        });
    }

    private fetchRefreshToken(): Observable<LoginOutDto> {
        return this.http.post(`${this.baseUrl}${this.gatewayUrl}${this.userUrl}${this.refreshTokenUrl}`, null, this.requestOptions);
    }

    private handleSuccess(loginOutDto: LoginOutDto): void {
        if (loginOutDto) {
            this.processAccessToken(loginOutDto);
            this.processRefreshToken(loginOutDto);
            this.checkAndNavigate();
        } else {
            this.refreshTokenExpired();
        }
    }

    private appendRequestParamsForRefreshToken(requestParams: RequestParam[]): void {
        this.requestOptions.params = new HttpParams({encoder: new CustomHttpUrlEncodingCodecUtil()});
        if (requestParams !== null && requestParams !== undefined) {
            requestParams.forEach(requestParam =>
                this.requestOptions.params = this.requestOptions.params.append(requestParam.key, requestParam.value));
        }
        this.addRefreshToken();
    }

    private addRefreshToken(): void {
        const refreshToken = localStorage.getItem(CAT_CONSTANTS.REFRESH_AUTH_TOKEN);
        refreshToken ? this.updateRefreshToken(refreshToken) : this.resetHeaders();
    }

    private updateRefreshToken(refreshToken: string): void {
        if (this.requestOptions.headers.has('Authorization')) {
            this.requestOptions.headers = this.requestOptions.headers.set('Authorization', null);
        }
        this.requestOptions.headers = this.requestOptions.headers.has('refresh-token')
            ? this.requestOptions.headers.set('refresh-token', refreshToken)
            : this.requestOptions.headers.append('refresh-token', refreshToken);
    }

    private resetHeaders(): void {
        this.requestOptions.headers = new HttpHeaders();
    }

    private checkForRedirectUrl(): void {
        this.activatedRoute.queryParamMap.subscribe(queryParamMap => {
            const redirectUrl = queryParamMap.get('redirectUrl');
            if (redirectUrl) {
                this.redirectUrl = redirectUrl;
            }
        });
    }

    private checkAndNavigate(): void {
        this.redirectUrl
            ? this.router.navigate([this.redirectUrl.split('?')[0]])
            : this.router.navigate([ROUTING.SLASH + ROUTING.HOME]);
    }

    private refreshTokenExpired(): void {
        this.libSnackbarService.open(this.translateService.instant('Oops! Your login token is expired. Please login'));
        this.logout();
    }

    private logout(): void {
        this.clearLocalStorageItems();
        this.navigateToLogin();
    }

    private clearLocalStorageItems(): void {
        localStorage.removeItem(ROUTING.SUPPORT);
        localStorage.removeItem(CAT_CONSTANTS.AUTH_TOKEN);
        localStorage.removeItem(CAT_CONSTANTS.REFRESH_AUTH_TOKEN);
    }

    private navigateToLogin(): void {
        this.router.navigate([ROUTING.SLASH + ROUTING.AUTH + ROUTING.SLASH + ROUTING.LOGIN])
            .then(() => {
                window.location.reload();
            });
    }

    private processAccessToken(loginOutDto: LoginOutDto): void {
        const accessToken = loginOutDto?.token?.accessToken;
        this.saveToken(CAT_CONSTANTS.AUTH_TOKEN, accessToken);
    }

    private processRefreshToken(loginOutDto: LoginOutDto): void {
        const refreshToken = loginOutDto?.token?.refreshToken;
        this.saveToken(CAT_CONSTANTS.REFRESH_AUTH_TOKEN, refreshToken);

    }

    private saveToken(tokenType: string, token: string): void {
        localStorage.setItem(tokenType, token);
    }

    private setBaseUrl(): void {
        this.baseUrl = environment.API_BASE_PATH;
    }
}
