export const CAT_CONSTANTS = {
    productPageSize: 25,
    documentPageSize: 10,
    supplierFilterLabel: 'SUPPLIER_FILTER',
    GATEWAY: 'PROCUREMENT',
    PROJECT_ID: 'projectId',
    AUTH_TOKEN: 'auth_token',
    REFRESH_AUTH_TOKEN: 'r_auth_token',
    CATALOG_TEMPLATE_URL: 'https://kataloge.cathago.de/',
    calendlyHelpLinkUrl: ' https://meetings-eu1.hubspot.com/daniel-rakicki/cs-inbound-meeting'
};
