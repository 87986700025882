import moment from 'moment/moment';
import {
    DATE_TIME_FORMAT,
    DATE_TIME_FORMAT_DISPLAY,
    LANGUAGE,
    LANGUAGE_LOCALE
} from "../../../../library/input/common/util/lib-formatter.util";

export class FormatterUtil {

    public static ZERO_TIME_UTC_FORMAT = 'T00:00:00.000Z';
    public static dateFormat = 'dd.MM.yyyy';
    public static timeFormat = 'HH:mm';
    public static timeFormat12Hr = 'hh:mm A';
    public static timeFormat24Hr = 'HH:mm';
    public static dateSeparator = '-';

    public static setDateFormat(dateFormat: string): void {
        this.dateFormat = dateFormat;
    }

    public static getDateFormat(): string {
        return this.dateFormat;
    }

    public static setTimeFormat(timeFormat: string): void {
        this.timeFormat = timeFormat;
    }

    public static getTimeFormat(): string {
        return this.timeFormat;
    }

    public static getDateFormatUTC(): string {
        return 'yyyy-MM-dd';
    }

    public static getDateTimeFormat(): string {
        return this.dateFormat + ' ' + this.timeFormat;
    }

    public static getFullDateFormat(): string {
        return 'EEEE, dd MMMM y';
    }

    public static hasTimePart(dateTimeString: string): boolean {
        return /\d{2}:\d{2}(:\d{2})?/.test(dateTimeString);
    }

    public static getDefaultDateFormatForInput(userDateFormat = this.dateFormat.toUpperCase()) {
        return {
            parse: {
                dateInput: userDateFormat.toUpperCase()
            },
            display: {
                dateInput: userDateFormat.toUpperCase(),
                monthYearLabel: 'MMM YYYY',
                dateA11yLabel: 'LL',
                monthYearA11yLabel: 'MMMM YYYY',
            }
        };
    }

    public static getDateTimeFormatDisplay(dateTimeFormat: string): string {
        switch (dateTimeFormat) {
            case DATE_TIME_FORMAT.ddMMyyyyhhmma:
                return DATE_TIME_FORMAT_DISPLAY.ddMMyyyyhhmma;
            case DATE_TIME_FORMAT.yyyyMMddhhmma:
                return DATE_TIME_FORMAT_DISPLAY.yyyyMMddhhmma;
            case DATE_TIME_FORMAT.yyyyMMddHHmm:
                return DATE_TIME_FORMAT_DISPLAY.yyyyMMddHHmm;
            case DATE_TIME_FORMAT.ddMMyyyyHHmm:
            default:
                return DATE_TIME_FORMAT_DISPLAY.ddMMyyyyHHmm;
        }
    }

    public static convertTimeToUTCFormat(time: string): string {
        const currentDateWithISOFormat = new Date().toISOString();
        const dateTimeWithISOFormat = new Date(currentDateWithISOFormat?.split('T')[0] + 'T' + time + ':00.000Z');
        const dateTimeWithTimezoneOffset = new Date(dateTimeWithISOFormat.getTime() +
            (dateTimeWithISOFormat.getTimezoneOffset() * 60 * 1000));
        const UTCHours = this.padStringWithZero(dateTimeWithTimezoneOffset.getUTCHours()?.toString());
        const UTCMins = this.padStringWithZero(dateTimeWithTimezoneOffset.getUTCMinutes()?.toString());
        const UTCHrsAndMins = UTCHours + ':' + UTCMins;
        return UTCHrsAndMins;
    }

    public static convertDateToUTCFormat(inputDate: string): string {
        const inputDateObject = new Date(inputDate);
        const UTCDateString = this.constructDateFromDateObject(inputDateObject) + this.ZERO_TIME_UTC_FORMAT;
        return UTCDateString;
    }

    public static constructDateFromDateObject(inputDateObject: Date): string {
        const dateString = this.padStringWithZero((inputDateObject.getDate())?.toString());
        const month = this.padStringWithZero((inputDateObject.getMonth() + 1)?.toString());
        const year = inputDateObject.getFullYear()?.toString();
        return year + this.dateSeparator + month + this.dateSeparator + dateString;
    }

    public static convertTo24HourFormat(time12Hr: string, locale: string): string {
        const momentObject = moment(time12Hr, this.timeFormat12Hr, this.getLocale(locale), true);
        return momentObject.format(this.timeFormat24Hr);
    }

    public static padStringWithZero(inputString: string): string {
        const formattedInputString = inputString?.padStart(2, '0');
        return formattedInputString;
    }

    private static getLocale(locale: string): string {
        switch (locale?.toLowerCase()) {
            case LANGUAGE.DE:
                return LANGUAGE_LOCALE.DE;
            case LANGUAGE.FR:
                return LANGUAGE_LOCALE.FR;
            case LANGUAGE.JA:
                return LANGUAGE_LOCALE.JA;
            case LANGUAGE.PL:
                return LANGUAGE_LOCALE.PL;
            default:
                return LANGUAGE_LOCALE.EN;
        }
    }
}
