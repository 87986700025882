import {Injectable} from '@angular/core';
import {getMessaging, onMessage} from '@angular/fire/messaging';
import {PushNotificationService} from "../../notification/common/service/push-notification.service";

@Injectable({
    providedIn: 'root'
})
export class UserMessagingFirebaseService {

    constructor(private pushNotificationService: PushNotificationService) {
    }

    listen(): void {
        const messaging = getMessaging();
        onMessage(messaging, (payload) => {
            const notificationOutDto = JSON.parse(payload?.data?.['notificationOutDto']);
            this.pushNotificationService.addToQueue(notificationOutDto);
        });
    }
}
