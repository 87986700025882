import {Injectable} from '@angular/core';
// TODO : THIS IS GIVING ERROR AT THE MOMENT SO COMMENTING PLEASE FIX IT LATER
//import {AngularFireAnalytics} from '@angular/fire/analytics/analytics';
import {EVENT} from '../constant/event.constant';
import {NavigationEnd, Router} from '@angular/router';
import {environment} from '../../../../../environments/environment';
import {UserMeOutDto} from "./user.me.service";

// tslint:disable-next-line:ban-types
declare var gtag: Function;

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {

    constructor(public router: Router,
                /*private angularFireAnalytics: AngularFireAnalytics*/) {
        if (environment.GOOGLE_ANALYTICS_MEASUREMENT_ID !== 'OFF') {
            this.startAnalytics();
        }
    }

    public registerUserForAnalytics(userMeOutDto: UserMeOutDto) {
        // this.angularFireAnalytics.setUserId(userMeOutDto.userOutDto.userId);
        this.setUserProperties(userMeOutDto);
        this.logEvent(EVENT.LOGIN, userMeOutDto);
    }

    public logEvent(eventName: string, data: any) {
        // TODO : THIS IS GIVING ERROR AT THE MOMENT SO COMMENTING PLEASE FIX IT LATER
        // this.angularFireAnalytics.logEvent(eventName, data);
        this.event(eventName, '', '', data);
    }

    public startAnalytics() {
        this.onRouteChange();

        try {
            const url = 'https://www.googletagmanager.com/gtag/js?id=';
            const gTagScript = document.createElement('script');
            gTagScript.async = true;
            gTagScript.src = `${url}${environment.GOOGLE_ANALYTICS_MEASUREMENT_ID}`;
            document.head.appendChild(gTagScript);

            const dataLayerScript = document.createElement('script');
            dataLayerScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${environment.GOOGLE_ANALYTICS_MEASUREMENT_ID}', {'send_page_view': false});`;
            document.head.appendChild(dataLayerScript);
        } catch (e) {
            console.error('Error adding Google Analytics', e);
        }
    }

    private onRouteChange() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                gtag('config', environment.GOOGLE_ANALYTICS_MEASUREMENT_ID, {
                    page_path: event.urlAfterRedirects,
                });
            }
        });
    }

    public event(action: string, eventCategory?: string, eventLabel?: string, value?: string) {
        // TODO : THIS IS GIVING ERROR AT THE MOMENT SO COMMENTING PLEASE FIX IT LATER
       /* gtag('event', action, {
            ...(eventCategory && {event_category: eventCategory}),
            ...(eventLabel && {event_label: eventLabel}),
            ...(value && {value}),
        });*/
    }

    private setUserProperties(userMeOutDto: UserMeOutDto) {
        // TODO : THIS IS GIVING ERROR AT THE MOMENT SO COMMENTING PLEASE FIX IT LATER
        /*this.angularFireAnalytics.setUserProperties({
            userId: userMeOutDto.userOutDto.userId,
            username: userMeOutDto.userOutDto.username,
            userType: userMeOutDto.userOutDto.userType,
            companyId: userMeOutDto.companyAccountUserOutDto.member.companyAccountOutDto.companyOutDto.id,
            companyName: userMeOutDto.companyAccountUserOutDto.member.companyAccountOutDto.companyOutDto.name
        });*/
    }
}
