import {throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ROUTING} from '../constant/routing.constant';
import {TranslateService} from '@ngx-translate/core';
import {TokenService} from '../../../auth-layout/common/service/token.service';
import {LibSnackbarService} from "../../../../library/snackbar/service/lib-snackbar.service";
import {LoggerService} from '../../../common/logger.service';

@Injectable({
    providedIn: 'root'
})
export class ExceptionService {

    private skipErrors = ['Warehouse not found!'];

    constructor(private router: Router,
                private tokenService: TokenService,
                private libSnackbarService: LibSnackbarService,
                private translateService: TranslateService,
                private loggerService: LoggerService) {
    }

    catchError = (error) => {
        this.loggerService.logError(error);
        this.handleStatusCode(error);
        return throwError(error);
    }

    private handleStatusCode(error) {
        switch (error?.status) {
            case 401:
                this.redirectToDashboard(error);
                break;
            case 403:
                this.handle403(error);
                break;
            default:
                this.showErrorMessage(error);
                break;
        }
    }

    private redirectToDashboard(error) {
        this.showErrorMessage(error);
        this.router.navigate([ROUTING.SLASH + ROUTING.HOME]).then(res => {
        });
    }

    private showErrorMessage(error) {
        const displayText = error?.error?.errorCode?.displayText;
        if (!this.skipErrors.includes(displayText)) {
            this.libSnackbarService.open(this.getErrorMessage(displayText));
        }
    }

    private getErrorMessage(displayText?: string) {
        return displayText
            ? this.translateService.instant(displayText)
            : this.translateService.instant('Something went wrong, please contact admin');
    }

    private handle403(error: any): void {
        if (error?.error?.errorCode?.code) {
            this.showErrorMessage(error);
        } else {
            this.tokenService.handle403();
        }
    }
}
